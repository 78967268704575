<template>
  <div class="talentServices">
    <div class="banner">
      <img src="./img/banner.png" alt="" />
    </div>
    <div class="section-content">
      <div
        class="content"
        v-for="(item, index) in listData"
        :key="index"
        @click="handelClickRedirect(item.url)"
      >
        <div class="content-img">
          <img :src="item.imgUrl" alt="" />
        </div>
        <div class="content-text">{{ item.text }}</div>
        <div class="content-arrow">
          <img :src="item.icon" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRegister } from "@/utils/common.js";
export default {
  name: "talentServices",
  components: {},
  props: {},
  data() {
    return {
      listData: [
        {
          imgUrl: require("./img/menu.png"),
          text: "十项菜单",
          icon: require("./img/arrow.png"),
          url: "tenItemMenu",
        },
        {
          imgUrl: require("./img/talent.png"),
          text: "人才认证",
          icon: require("./img/arrow.png"),
          url: "talentCertification",
        },
      ],
    };
  },
  created() {},
  async mounted() {
    // await toRegister(this.$route.path, this.$route.query, "人才服务");
  },
  methods: {
    //跳转页面
    handelClickRedirect(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>

<style scoped lang="less">
.talentServices {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fafafa;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .banner {
    width: 100%;
    height: 336px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .section-content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 18px 30px 30px;
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(218, 216, 216, 0.5);
      border-radius: 16px;
      padding: 50px 26px 48px 28px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .content-img {
        width: 140px;
        height: 140px;
        margin-right: 54px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .content-text {
        flex: 1;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #000000;
        line-height: 44px;
      }
      .content-arrow {
        width: 48px;
        height: 48px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
